import React from 'react';
import PesquisaService from '../service/PesquisaService';

class Home extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            produto: {
                produto: [],
                desc: '',
                chaveStr: ''
            }
          }
        }

    componentDidMount(){
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let chave = params.get('nome') ? {nome: params.get('nome')} : {nome : ''};
        
        PesquisaService.post(chave).then(response => {
            let produto = this.state.produto;
            
            produto = response.data.produto;
            this.setState({produto: produto});

            let desc = this.state.produto.produto[0] ? Object.values(this.state.produto.produto[0]) : '';
            let descStr = desc ? desc[1] : '';
            this.setState({desc: descStr})
            let chaveStr = (params.get('nome') ? params.get('nome') : '');
            let label = desc ? 'Chave: ': '';
            this.setState({chaveStr: chaveStr, label: label} ? {chaveStr: chaveStr, label: label}: '' );
        })
    }

    render(){
        return (
            <>
            <div className="outsider">
            <div className="SearchB">
                <form>
                    <label htmlFor="chave">
                        <b>Código de rastreamento: </b>
                    </label>
                    <input type="text" name="nome" />
                    <button type="submit" value="Pesquisar" id="btnPesquisar"> Rastrear </button><br />
                </form>
            </div>
            
            
                <h2 id="desc">{this.state.desc}</h2>
                <h4 id="desc">{this.state.label}{this.state.chaveStr}</h4>
            </div>
            
            <div className="grid-container">
              {this.state.produto.produto.map((item, i) => (
                <div className="grid-item" id key={i}>  
                <b id="titulo">{i+1} {item.localTitulo}</b><br />
                <b id="descricao">{item.localDescricao}</b><br />
                <span id="endereco">{item.local_endereco}</span><br />
                <span id="endereco">IE: {item.localInscricao}</span>
                </div>
                ))}
            </div>
          </>
        );
    }
}

export default Home;