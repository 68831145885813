import axios from 'axios';

class RequisicaoService {
    constructor(urlBase) {
        this.urlBase = urlBase !== undefined ? urlBase  : '';
    }

    static headers(config) {
        if(config === undefined) {
            config = {headers: {}};
        }

        if(config.headers === undefined) {
            config.headers = {};
        }

        return config;
    }

    static async get(url, config) {
        return axios.get(url, RequisicaoService.headers(config));
    }

    static async post(url, data, config) {
        return axios.post(url, data, RequisicaoService.headers(config));
    }

    static async delete(url, config) {
        return axios.delete(url, RequisicaoService.headers(config));
    }
}

export default RequisicaoService;