const config = {};

config.urlBase = 'https://www.rastreio.organicosdamantiqueira.com.br/backend/api/';
// config.urlBaseImg = 'http://localhost/coleta_pedidos_app_empresa/img/logo/';

if (process.env.NODE_ENV === "production") {
    config.urlBase = 'https://www.rastreio.organicosdamantiqueira.com.br/backend/api/';
    // config.urlBaseImg = 'https://coletapedido.com.br/empresa/img/logo/';    
}

export default config;
