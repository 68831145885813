import {Toolbar} from "@material-ui/core";
import React from "react";

class Header extends React.Component {

    render() {
    return (
        <Toolbar className="App-header">
        <img src="logo.png" alt="mantiqueira"/>
        <a href="./"><span className="header-text"> Rastreamento de Produtos </span></a>
        </Toolbar>
        );
      }
    }  
export default Header;