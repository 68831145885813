import React from 'react';
import Header from './view/header';
import Footer from './view/footer';
import Home from './view/home';
import './App.css';

class App extends React.Component {
    render(){
        return (
            <div className="App">
                <Header />
                    <Home />
                <Footer />
            </div>
        );
    }
}

export default App;
